import { WontFix } from 'global';
import he from 'he';
import { ComponentProps, ComponentPropsWithoutRef } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { RcLink, RcLinkVariant } from '@/components/rc-link';
import { cn } from '@/utils/tailwind';

export const Markdown = ({
  children,
  linkVariant,
  markdownTransform = (markdown) => (he ? he.decode(markdown) : markdown),
  overrides,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof ReactMarkdown> & {
  linkVariant?: RcLinkVariant;
  markdownTransform?: (markdown: string) => string;
  overrides?: Record<
    string,
    ({
      node,
      props,
    }: {
      // We add WontFix here cause node is the original hast element turned into React element
      // and we don't use it anywhere, just added to get all the remaining props
      // Ref: https://github.com/remarkjs/react-markdown/tree/main
      node: WontFix;
      // We will replace WontFix with the props type of
      // component that we desire to use for this element
      props: ComponentProps<WontFix>;
    }) => JSX.Element
  >;
}) => {
  // markdownTransform: A way for each consumer of this component to apply string transformations before converting to Markdown.
  // Ex: https://kaligo.atlassian.net/browse/BUG-2561
  // Transformation was applied in GC About tab as we need to convert single `\n`
  // to double `\n\n` to force line breaks

  // Allowed components:
  // By default, we'll render RcLink for a, Typography.Paragaph for p
  // However, the user can override by inputting in a function for "a", etc.
  // Check Footer for an example.

  if (!children) {
    return null;
  }

  return (
    <ReactMarkdown
      // @ts-expect-error: don't know why when changing this to normal function just like in the docs
      components={{
        // it doesn't work. if we omit the function params, it works
        a: ({
          node,
          ...props
        }: {
          node: WontFix;
          props: ComponentProps<typeof RcLink>;
        }) => (
          // TODO: do we need to use RcLink here since we only need the style instead of using next.js Link component
          // @ts-expect-error: we have href in the props
          <RcLink
            // @ts-expect-error: we have href in the props
            target={props.href?.startsWith('http') ? '_blank' : ''}
            variant={linkVariant || ('primary' as RcLinkVariant)}
            {...props}
          />
        ),
        p: ({
          node,
          ...props
        }: {
          node: WontFix;
          props: React.HTMLAttributes<HTMLParagraphElement>;
        }) => <p className="" {...props} />,
        h1: ({
          node,
          ...props
        }: {
          node: WontFix;
          props: React.HTMLAttributes<HTMLHeadingElement>;
        }) => (
          <h1
            className="mb-2 font-heading text-3xl-bold md:text-4xl-bold"
            {...props}
          />
        ),
        h2: ({
          node,
          ...props
        }: {
          node: WontFix;
          props: React.HTMLAttributes<HTMLHeadingElement>;
        }) => (
          <h2
            className="mb-2 font-heading text-2xl-bold md:text-3xl-bold"
            {...props}
          />
        ),
        h3: ({
          node,
          ...props
        }: {
          node: WontFix;
          props: React.HTMLAttributes<HTMLHeadingElement>;
        }) => (
          <h3
            className="mb-2 font-heading text-lg-bold md:text-xl-bold"
            {...props}
          />
        ),
        ol: ({
          node,
          ...props
        }: {
          node: WontFix;
          props: React.HTMLAttributes<HTMLOListElement>;
        }) => <ol className="mb-2 pl-4" {...props} />,
        ul: ({
          node,
          ...props
        }: {
          node: WontFix;
          props: React.HTMLAttributes<HTMLUListElement>;
        }) => <ul className="mb-2 list-disc pl-4" {...props} />,
        // @ts-expect-error: same as above
        ol: ({
          node,
          ...props
        }: {
          node: WontFix;
          props: React.HTMLAttributes<HTMLOListElement>;
        }) => <ol className="mb-2 list-decimal pl-4" {...props} />,
        li: ({
          node,
          ...props
        }: {
          node: WontFix;
          props: React.HTMLAttributes<HTMLLIElement>;
        }) => <li className="list-inside" {...props} />,
        strong: 'strong',
        table: ({ node: _node, children, className, ...props }) => {
          return (
            <table className={cn('border-collapse', className)} {...props}>
              {children}
            </table>
          );
        },
        td: ({ node: _node, children, className, ...props }) => {
          return (
            <td
              className={cn(
                'border-[0.5px] border-neutral-400 p-4 text-start align-baseline',
                className,
              )}
              {...props}
            >
              {children}
            </td>
          );
        },
        th: ({ node: _node, children, className, ...props }) => {
          return (
            <th
              className={cn(
                'border-[0.5px] border-neutral-400 p-4 text-start align-baseline text-primary-400',
                className,
              )}
              {...props}
            >
              {children}
            </th>
          );
        },
        ...overrides,
      }}
      rehypePlugins={[rehypeRaw]}
      className={cn('break-words', className)}
      {...props}
    >
      {/* Single  */}
      {markdownTransform(children)}
    </ReactMarkdown>
  );
};

Markdown.displayName = 'Markdown';
