import { User } from '@/auth/schema';
import { cn } from '@/utils/tailwind';
import * as RadixAvatar from '@radix-ui/react-avatar';

// According to design system
// https://www.figma.com/file/RimBzcuzFBi9xhXotSeINt/Homepage?node-id=45%3A699&t=iaa0WLWK8m26PbKb-4
export enum AvatarHeights {
  LARGE = 64,
  MEDIUM = 36,
  SMALL = 24,
}

export interface AvatarProps {
  name: string;
  picture?: string;
  height?: AvatarHeights;
  className?: string;
  variant: 'primary' | 'secondary';
}

/**
 * @deprecated
 * This component is using `@radix-ui` which is deprecated, we need to migrate from `@radix-ui` to `react-aria-components`.
 */
export function Avatar({
  picture,
  name,
  height = AvatarHeights.MEDIUM,
  className = '',
  variant = 'primary',
}: AvatarProps) {
  // John Smith -> JS
  const nameInitials = name
    .split(' ')
    .slice(0, 2)
    .map((x) => x[0])
    .join('')
    .toUpperCase();

  const widthClasses = () => {
    switch (height) {
      case AvatarHeights.LARGE:
        return 'h-[54px] w-[54px]';
      case AvatarHeights.SMALL:
        return 'h-6 w-6';
      case AvatarHeights.MEDIUM:
        return 'h-8 w-8';
      default:
        return 'h-8 w-8';
    }
  };

  return (
    <RadixAvatar.Root className={className}>
      {picture && (
        <RadixAvatar.Image
          src={picture}
          height={height}
          width={height}
          className={cn(widthClasses(), 'rounded-full')}
        />
      )}
      <RadixAvatar.Fallback
        className={cn(
          widthClasses(),
          variant === 'secondary'
            ? 'bg-secondary hover:bg-secondary-400'
            : 'bg-primary hover:bg-primary-400',
          'flex items-center justify-center rounded-full text-white',
        )}
      >
        {nameInitials}
      </RadixAvatar.Fallback>
    </RadixAvatar.Root>
  );
}

export function getAvatarNameFromUser(user: User | null | undefined): string {
  if (user?.name) {
    return user.name;
  }

  // if first name or last name has more than one word, only use the first word
  const firstName = user?.firstName ? user.firstName.split(' ')[0] : '';
  const lastName = user?.lastName ? user.lastName.split(' ')[0] : '';

  const combinedName = [firstName, lastName].filter(Boolean).join(' ');
  if (combinedName !== '') {
    return combinedName;
  }

  /**
   * NOTE(sontruong):
   * no need to translate 'Ascenda Go' text
   * since it's just used for displaying avatar fallbacks
   */

  return user?.email || 'Ascenda Go';
}
