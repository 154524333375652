'use client';

import { ReactNode, createContext, useState } from 'react';

export const RedirectModalContext = createContext<{
  showRedirectModal: boolean;
  setShowRedirectModal: (show: boolean) => void;
}>({
  showRedirectModal: false,
  setShowRedirectModal: () => {},
});

export default function RedirectModalProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  return (
    <RedirectModalContext.Provider
      value={{
        showRedirectModal,
        setShowRedirectModal,
      }}
    >
      {children}
    </RedirectModalContext.Provider>
  );
}
